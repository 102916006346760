import React, { useEffect } from "react";
import { TfiMenuAlt } from "react-icons/tfi";
import { RxCrossCircled } from "react-icons/rx";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import PageHeader from "../../component/PageHeader/PageHeader";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getOwnWaitlist,
  leaveWaitlist,
} from "../../Redux/features/WaitlistSlice";
import Loading from "../../component/Loading";

const Confirmation = () => {
  const dispatch = useDispatch();
  const params = useParams().id;
  const status = useSelector((state) => state.Waitlist.status);

  useEffect(() => {
    dispatch(getOwnWaitlist(params));
  }, [dispatch, params]);
  const singleWaitlist = useSelector((state) => state.Waitlist.singleWaitlist);
  console.log(singleWaitlist);
  return (
    <>
      <PageHeader name={"Confirmation Page"}></PageHeader>
      <div id="confirm" className="container my-5 py-5">
        <div className="row justify-content-center">
          <div className="col-md-10 py-2 mb-5 col-lg-9 col-xxl-6 col-11 custom-border">
            <div className="row">
              <div className="col-12 pt-3">
                <p className="text-center mb-0">BarberShop</p>
                <div className="text-center py-3">
                  <IoMdCheckmarkCircleOutline
                    style={{ fontSize: "150px", color: "var(--primary-color)" }}
                  />
                </div>
                <h2
                  className="text-center display-1 fw-semibold mb-3
                "
                >
                  Thanks for waiting!
                </h2>
                <p className="text-center mb-0 fw-semibold">
                  Stay on this page to get notified when it's your turn.
                </p>
              </div>
            </div>
            {status === "loading" ? (
              <Loading />
            ) : (
              <>
                {" "}
                <div>
                  <div className="row justify-content-center text-center mt-4">
                    <div className="col-6">
                      <p className="mb-0">Place in line</p>
                      <p>{singleWaitlist?.serialNumber}</p>
                    </div>
                    <div className="col-6">
                      <p className="mb-0">Estimate Wait</p>
                      <p>{singleWaitlist?.waitingTime} min</p>
                    </div>
                  </div>
                  <div className="row mt-5 pb-4 justify-content-center text-center">
                    {singleWaitlist?.map((item) => (
                      <>
                        <div key={item.id} className="col-lg-3 pb-3 col-6">
                          <p className="mb-0 ">Name</p>
                          <p className="mb-0 fw-bolder pb-1">{item?.name}</p>
                        </div>
                        <div className="col-lg-3 pb-3  col-6">
                          <p className="mb-0 ">phone</p>
                          <p className="mb-0 fw-bolder pb-1">{item?.phone}</p>
                        </div>
                        <div className="col-lg-3 pb-3  col-6">
                          <p className="mb-0 ">Staff</p>
                          <p className="mb-0 fw-bolder pb-1">
                            {item?.employeeName}
                          </p>
                        </div>
                        <div className="col-lg-3 pb-3  col-6">
                          <p className="mb-0 ">Services</p>
                          <p className="mb-0 fw-bolder">{item?.serviceName}</p>
                        </div>
                      </>
                    ))}
                  </div>
                  <hr className="pt-4" />
                  <div className="row pb-4 text-center justify-content-center align-items-center">
                    <div className="col-6">
                      <Link
                        to="/waitlistPage"
                        className="my-3 fw-bolder text-black"
                      >
                        <TfiMenuAlt /> View Waitlist
                      </Link>
                    </div>
                    <div className="col-6">
                      <Link to="/" className="mb-0 fw-bolder text-black">
                        <RxCrossCircled /> Leave Waitlist
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Confirmation;
