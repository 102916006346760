import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAsyncAllImages } from "../../Redux/features/GallerySlice";
import Loading from "../../component/Loading";

const Gallery = () => {

  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    dispatch(getAsyncAllImages())
  }, [dispatch]);
  const allImage = useSelector((state) => state.Gallery.allImage);
  const status = useSelector((state) => state.Gallery.allImageStatus);
console.log("status---", status)

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };
  useEffect(() => {
    const element = document.getElementById('gallery-head');
    if (element) element.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <div id="gallery-head">
      <div id="wrapper">
        <div className="no-bottom no-top" id="content">
          <div id="top"></div>
          <div className="slider-area2">
            <div className="slider-height2 d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="hero-cap hero-cap2 pt-70 text-center">
                      <h2>Gallery</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="no-top gallery-background" aria-label="section">
            <div className="container-fluid pb-3">
              <div id="gallery" className="row g-3 px-2">
                {status === "loading" ? (
                  <Loading />
                ) : (
                  allImage?.map((gallery, index) => (
                    <div
                      key={index}
                      className="col-md-6 col-lg-3 col-12"
                    >
                      <div
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        className="de-image-hover rounded"
                        onClick={() => openModal(gallery.url)}
                      >
                        <span className="dih-overlay"></span>
                        <img
                          src={gallery.url}
                          className="lazy gallery-img"
                          alt=""
                        />
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </section>
        </div>

        {/* modal */}
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          onClick={closeModal}
        >
          <div className="modal-dialog modal-dialog-centered  modal-xl">
            <div
              className="modal-content"
              style={{ backgroundColor: "var(--secondary-color)" }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="modal-body">
                {selectedImage && (
                  <img
                    className="justify-content-center align-content-center img-fluid"
                    style={{ width: "100%", objectFit: "cover" }}
                    src={selectedImage}
                    alt="Selected"
                  />
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn header-btn text-black"
                  data-bs-dismiss="modal"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>  
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default Gallery;
