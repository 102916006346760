import { FaFacebook, FaLocationArrow } from "react-icons/fa6";
import { FaInstagram, FaMapMarkerAlt, FaTiktok } from "react-icons/fa";
import { FaLevelUpAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAsyncSocialLinks } from "../../Redux/features/CommonSlice";
import { getAsyncContact } from "../../Redux/features/ContactSlice";

const Footer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAsyncSocialLinks());
  }, [dispatch]);
    // Fetch data from API
    useEffect(() => {
      dispatch(getAsyncContact());
    }, [dispatch]);
    
    const socialLink = useSelector((state) => state.Common.socialLinks);
    const contact = useSelector((state) => state.Contact.contacts);

    // Extract the last 8 digits of the phone number
    const newPhoneNumber = contact.phone ? contact.phone.slice(-8) : '';

  return (
    <>
      <footer className="footer-bg">
        <div className="footer-area section-bg">
          <div className="container">
            <div className="footer-top footer-padding">
              <div className="row d-flex justify-content-between">
                <div className="col-xl-3 col-lg-4 col-md-5 col-sm-8">
                  <div className="single-footer-caption mb-50">
                    <div className="footer-logo">
                      <img
                        style={{ width: "170px" }}
                        src="img/logo/ABSS.webp"
                        alt=""
                      />
                    </div>
                    <div className="footer-tittle">
                      <div className="footer-pera">
                        <p className="info1">
                          OUR HAIR STYLE MAKE YOUR LOOK ELEGANCE
                        </p>
                      </div>
                    </div>
                    <div className="footer-number">
                      <h4>
                        <span>+961 </span> {newPhoneNumber}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-5">
                  <div className="single-footer-caption mb-50">
                    <div className="footer-tittle">
                      <h4>Pages</h4>
                      <ul>
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="about"> About</Link>
                        </li>
                        <li>
                          <Link to="/services">Services</Link>
                        </li>
                        <li>
                          <Link to="gallery">Gallery</Link>
                        </li>
                        <li>
                          <Link to="contact">Contact</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-8">
                  <div className="single-footer-caption mb-50">
                    <div className="footer-tittle">
                      <h4>Location</h4>
                      <div className="footer-pera">
                        <p className="info1">
                          <FaMapMarkerAlt className="me-1" /> Lebanon
                        </p>

                        <Link style={{ color: "var(--text-secondary-color)" }}>
                          <FaLocationArrow className="me-1" /> Get Direction
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="row justify-content-center align-content-center">
                <div className="col-6 align-content-center">
                  <div className="footer-copy-right">
                    <p>
                      Copyright &copy;
                      <script>document.write(new Date().getFullYear());</script>
                      2023 -
                      <a href="https://abss.tech" target="blank">
                        ABSS
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="footer-social f-right">
                    <a
                      href={socialLink?.facebook}
                      target="blank"
                      className="fs-2"
                      style={{ color: "var(--primary-color)" }}
                    >
                      <FaFacebook />
                    </a>
                    <a
                      href={socialLink?.instagram}
                      target="blank"
                      style={{ color: "var(--primary-color)" }}
                      className="mx-4 fs-2"
                    >
                      <FaInstagram />
                    </a>
                    <a
                      href={socialLink?.tiktok}
                      target="blank"
                      className="fs-2"
                      style={{ color: "var(--primary-color)" }}
                    >
                      <FaTiktok />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div>
        <a id="back-top" className="text-black" title="Go to Top" href="#">
          <FaLevelUpAlt style={{ color: "var(--secondary-color)" }} />
        </a>
      </div>
    </>
  );
};

export default Footer;
