import Gallery from "../../component/Gallery/Gallery";
import Header from "../../component/Header/Header";
import Services from "../../component/Pricing/Services";
import Schedule from "../../component/Schedule/Schedule";
import Trending from "../../component/Trending/Trending";
import Wizard from "../../component/WaitList/Steps/Wizard";



const HomePage = () => {
  return (
    <>
      <Header></Header>
      <Wizard></Wizard>
      <Services></Services>
      <Trending></Trending>
      <Gallery></Gallery>
      <Schedule></Schedule>
    </>
  );
};

export default HomePage;
