const WaitList = ({setGoSteps, handleButtonClick}) => {
  return (
    <>
      <div className="container">
        <div className="row justify-content-center my-5 ">
          <div className="custom-border col-lg-8 col-md-10 col-12 py-5 text-center">
            <h2 className="pt-5 display-4 fw-bold ">
              Welcome to our Barber shop
            </h2>
            {/* <p>2 People Waiting</p> */}
            <div className="row justify-content-center">
              <div className="col-8 col-lg-6">
                <button onClick={() => handleButtonClick("waitlist")} className="boxed-btn d-block mx-auto my-5 w-100 px-0">
                  Join WaitList
                </button>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-8 col-lg-6">
                <button onClick={() => handleButtonClick("reservation")} className="boxed-btn disabled d-block mx-auto my-5 w-100 px-0">
                  Reservation
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WaitList;
