import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAsyncCategories } from "../../Redux/features/CommonSlice";

const ServicesCompTwo = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAsyncCategories());
  }, [dispatch]);
  const categories = useSelector((state) => state.Common.categories);

  return (
    <div className="container pb-100">
      <div className="row justify-content-center">
        {categories?.map((singleCat) => (
          <div key={singleCat.id} className="col-lg-3 col-md-5 mx-2 my-4">
            <div className="services-caption text-center">
              <div className="service-icon">
                <img
                  className="img-fluid rounded-4"
                  width={120}
                  src={singleCat.url}
                  alt=""
                />
              </div>
              <div className="service-cap">
                <h4>
                  <Link to={"/services"}>{singleCat.title}</Link>
                </h4>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesCompTwo;
