import { AiOutlineArrowLeft } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAsyncCategories } from "../../Redux/features/CommonSlice";

const SelectService = ({ setGoSteps, onServiceSelect  }) => {
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null);


  const handleItemClick = (id) => {
    setSelectedItem(id);
  };

  const handleNextStep = () => {
    onServiceSelect(selectedItem);
    setGoSteps(2);
  };

  useEffect(() => {
    dispatch(getAsyncCategories());
  }, [dispatch]);
  const categories = useSelector((state) => state.Common.categories);

  return (
    <>
      <div className="row justify-content-center mx-1">
        <div className="col-md-10 col-lg-9 col-xxl-7 col-12 py-2 text-center custom-border">
          <div className="row">
            <div className="col-2 text-start">
              <AiOutlineArrowLeft
                style={{ background: "var(--primary-color)" }}
                onClick={() => setGoSteps(0)}
                className=" gj-cursor-pointer back-btn display-1 p-1 rounded"
              ></AiOutlineArrowLeft>
            </div>
            <div className="col-8 text-center pb-3">
              <h2 className=" display-4 fw-bold ">Select Services</h2>
            </div>
            <div className="col-2 text-start"></div>
          </div>
          <div className="row justify-content-center">
            <div className="col-11 overflow-auto mobile-height ">
              <div className="row justify-content-center">
                {categories.map((category, index) => (
                  <div className="col-lg-10 col-11" key={index}>
                    <div className="row">
                      <div className="col-12 p-3 my-5 custom-border">
                        <h1 className="mb-4 text-start">{category.name}</h1>
                        {category.services.map((service, serviceIndex) => (
                          <div
                            key={service.id}
                            className={`form-check mx-auto category-border col-lg-12 col-11 px-4 my-3 ${
                              selectedItem === service.id ? "service-bg" : ""
                            }`}
                          >
                            <div
                              onClick={() => handleItemClick(service.id)}
                              className="row justify-content-center"
                            >
                              <div className="col-12 pl-30 py-3 text-start">
                                <p className="fs-3 pb-2 fw-bold m-0 user-select-none">
                                  {service.name}
                                </p>
                                <p className="mb-3 user-select-none">
                                  {service.description}
                                </p>
                                <p className="m-0 user-select-none">
                                  <span className="me-3">
                                    <BiTimeFive></BiTimeFive>{" "}
                                    {service.durationInMinutes} minutes
                                  </span>
                                  <span>$ {service.waitlistPriceInDollar}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-8 col-lg-6 mx-auto">
            <button
              onClick={handleNextStep}
              className="boxed-btn d-block mx-auto my-5 w-100 px-0"
            >
              Reservation
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default SelectService;
