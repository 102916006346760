import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";
import Swal from "sweetalert2";

// Post Reservation
export const createAsyncReservation = createAsyncThunk(
  "reservation/createReservation",
  async ({ employeeId, serviceId, data, navigate }) => {
    try {
      const res = await axiosInstance.post(
        `/reservations/employees/${employeeId}/services/${serviceId}`,
        data
      );
      Swal.fire({
        icon: "success",
        title: "Your appointment has been booked successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate(`/confirmation/${res.data[0].id}`);
      return res.data;
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  }
);

// Velify Phone Number

export const VerifyAsyncPhoneNumber = createAsyncThunk(
  "reservation/verifyPhoneNumber",
  async ({ ReservationId, data }) => {
    console.log(ReservationId, data);
    try {
      const res = await axiosInstance.post(
        `/reservations/verify/${ReservationId}`,
        data
      );
      console.log(res.data);
      Swal.fire({
        icon: "success",
        title: "Your Phone Number has been verified successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      return res.data;
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  }
);

// Get Todays Reservation
export const getTodaysReservation = createAsyncThunk(
  "reservation/getTodaysReservation",
  async () => {
    try {
      const res = await axiosInstance.get("/reservations/today");
      console.log(res.data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);


// Get Own Reservation
export const getOwnReservation = createAsyncThunk(
  "reservation/getOwnReservation",
  async (ReservationId) => {
    console.log(ReservationId);
    try {
      const res = await axiosInstance.get(`/reservations/track/${ReservationId}`);
      console.log(res.data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
// Get Service Employees
export const getServiceEmployees = createAsyncThunk(
  "reservation/getServiceEmployees",
  async (serviceId) => {
    try {
      const res = await axiosInstance.get(`services/${serviceId}/employees`);
      console.log(res.data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  reservation: [],
  todaysReservation: [],
  serviceEmployees: [],
  singleReservation: [],
  verifyPhone: {},
  status: "idle",
  error: null,
};

const reservationSlice = createSlice({
  name: "reservation",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Post Reservation
      .addCase(createAsyncReservation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createAsyncReservation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.reservation = action.payload;
      })
      .addCase(createAsyncReservation.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Verify Phone Number
      .addCase(VerifyAsyncPhoneNumber.pending, (state) => {
        state.status = "loading";
      })
      .addCase(VerifyAsyncPhoneNumber.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.verifyPhone = action.payload;
      })
      .addCase(VerifyAsyncPhoneNumber.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Get Todays Reservation
      .addCase(getTodaysReservation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTodaysReservation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.todaysReservation = action.payload;
      })
      .addCase(getTodaysReservation.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // get Own Reservation
      .addCase(getOwnReservation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getOwnReservation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.singleReservation = action.payload;
      })
      .addCase(getOwnReservation.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Get Service Employees
      .addCase(getServiceEmployees.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getServiceEmployees.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.serviceEmployees = action.payload;
      })
      .addCase(getServiceEmployees.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default reservationSlice.reducer;
