import { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  getServiceEmployees,
} from "../../Redux/features/WaitlistSlice";
import Loading from "../Loading";

const SelectEmployee = ({ setGoSteps, onEmployeeSelect, selections }) => {
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null);
  console.log(selectedItem);

  const handleItemClick = (id) => {
    setSelectedItem(id);
  };

  const handleNextStep = () => {
    onEmployeeSelect(selectedItem);
    setGoSteps(3);
  };

  useEffect(() => {
    dispatch(getServiceEmployees(selections.serviceId));
  }, [dispatch, selections.serviceId]);

  const employees = useSelector((state) => state.Waitlist.serviceEmployees);
  console.log(employees);
  const status = useSelector((state) => state.Waitlist.status);

  return (
    <>
      <div className="row justify-content-center mx-1">
        <div className="col-md-10 col-lg-9 col-xxl-7 col-12 py-2 text-center custom-border">
          <div className="row">
            <div className="col-2 text-start">
              <AiOutlineArrowLeft
                style={{ background: "var(--primary-color)" }}
                onClick={() => setGoSteps(1)}
                className=" gj-cursor-pointer back-btn display-1 p-1 rounded"
              ></AiOutlineArrowLeft>
            </div>
            <div className="col-8 text-center pb-3">
              <h2 className=" display-4 fw-bold ">Select Staff</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 overflow-auto mobile-height">
              <div className="row justify-content-center">
                {status === "loading" ? (
                  <Loading />
                ) : (
                  <>
                    {employees.map((item) => (
                      <div
                        key={item.id}
                        className={`form-check category-border col-xl-3 col-lg-5 col-5 px-0  mx-2 my-3 ${
                          selectedItem === item.id ? "service-bg" : ""
                        }`}
                      >
                        <div
                          onClick={() => handleItemClick(item.id)}
                          className="row mx-0"
                        >
                          <div className="col-12 py-3 text-center">
                            <img
                              src={item.url}
                              className="img-fluid rounded"
                              alt={item.title}
                            />
                          </div>
                          <div className="col-12 mx-auto py-1 text-center">
                            <p className="fs-4 pb-2 fw-bold m-0 user-select-none d-inline-block">
                              {item.name}
                            </p>
                              <p className="fs-4 pb-2 m-0 user-select-none">
                                {item.waitingTime?.waitingTime}
                              </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-8 col-lg-6 mx-auto">
            <button
              onClick={handleNextStep}
              className="boxed-btn d-block mx-auto my-5 w-100 px-0"
            >
              Reservation
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectEmployee;
