import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchAsyncGetHome } from "../../Redux/features/HomeSlice";

const Header = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAsyncGetHome());
  }, [dispatch]);
  const homeData = useSelector((state) => state.Home.home);

  return (
    <>
      <div className="slider-area  fix vh-100">
        <div className="slider-active">
          <div className="single-slider slider-height d-flex align-items-center">
            <div className="container">
              <div className="row">
                <div className="col-xl-8 col-lg-9 col-md- col-sm-10">
                  <div className="hero__caption">
                    <h1 data-animation="fadeInUp" data-delay="0.5s">
                      {homeData?.title}
                    </h1>
                    <span data-animation="fadeInUp" data-delay="0.2s">
                      {homeData?.subTitle}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="stock-text">
          <h2>Get More confident</h2>
          <h2>Get More confident</h2>
        </div>
        <div className="thumb-content-box">
          <div className="thumb-content">
            <h3>make an appointment now</h3>
            <Link href="#">
              {" "}
              <i className="fas fa-long-arrow-alt-right"></i>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
