import { useDispatch, useSelector } from "react-redux";
import Services from "../../component/Pricing/Services";
import Team from "../../component/Team/Team";
import { useEffect } from "react";
import { getAsyncAboutDetails } from "../../Redux/features/AboutSlice";

const About = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAsyncAboutDetails());
  }, [dispatch]);

  const aboutDetails = useSelector((state) => state.About.about);
  




  return (
    <div>
      {/* About Header Start*/}
      <div className="slider-area2">
        <div className="slider-height2 d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="hero-cap hero-cap2 pt-70 text-center">
                  <h2>About US</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About Header End */}
      <section className="about-area py-5 position-relative px-2">
        <div className="container-fluid">
          <div className="row align-items-center ">
            <div className="col-lg-4 col-md-6 text-center mx-auto my-5">
              <img
                src="img/gallery/work-4.webp"
                className="img-fluid image-about"
                alt=""
              />
            </div>
            <div className="col-lg-8 col-md-12 mx-auto">
              <div className="about-caption">
                <div className="section-tittle section-tittle3 mb-35">
                  <p>About Our company</p>
                  <h2>{aboutDetails?.title}</h2>
                </div>
                <p className="mb-30 pera-bottom">{aboutDetails?.description}</p>
                <img src="img/gallery/signature.webp" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="about-shape">
          <img src="img/gallery/about-shape.webp" alt="" />
        </div>
      </section>
      <Services />
      <Team />
    </div>
  );
};

export default About;
