import React from "react";
import ReactDOM from "react-dom/client";
import Router from "./Router/Router";
import { RouterProvider } from "react-router-dom";

import "./assets/css/bootstrap.min.css";
import "./assets/css/flaticon.css";
import "./assets/css/trending.css";
import "./assets/css/schedule.css";
import "./assets/css/waitlist.css";
import "./assets/css/reservation.css";
import "./assets/css/select-service.css";
import "./assets/css/otp.css";
import "./assets/css/style.css";
import { Provider } from "react-redux";
import store from "./Redux/store";


ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={Router}></RouterProvider>
    </Provider>
  </React.StrictMode>
);
