import { createBrowserRouter } from "react-router-dom";
import Main from "./Main";
import Preloader from "../component/Preloader/Preloader";
import HomePage from "../Pages/HomePage/HomePage";
import About from "../Pages/About/About";
import Contact from "../Pages/Contact/Contact";
import Gallery from "../Pages/Gallery/Gallery";
import ServicesPage from "../Pages/ServicesPage/ServicesPage";
import { Toaster } from "react-hot-toast";
import WaitlistPage from "../Pages/WaitlistPage/WaitlistPage";
import Confirmation from "../Pages/Confirmation/Confirmation";

const Router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Main></Main>
        <Toaster />
        <Preloader></Preloader>
      </>
    ),
    children: [
      { path: "/", element: <HomePage></HomePage> },
      { path: "about", element: <About></About> },
      { path: "contact", element: <Contact></Contact> },
      { path: "gallery", element: <Gallery></Gallery> },
      { path: "services", element: <ServicesPage></ServicesPage> },
      { path: "waitlistPage", element: <WaitlistPage></WaitlistPage> },
      { path: "confirmation/:id", element: <Confirmation></Confirmation> },
    ],
  },

]);
export default Router;
