import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";

// all images
export const getAsyncAllImages = createAsyncThunk(
  "images/allImages",
  async () => {
    try {
      const res = await axiosInstance.get("/barbershop/images");
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
);

// favorite images
export const getAsyncFavoriteImages = createAsyncThunk(
  "images/favoriteImages",
  async () => {
    try {
      const res = await axiosInstance.get("/barbershop/images/favourite");
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
);

const initialState = {
  // all image
  allImage: [],
  allImageStatus: "idle",
  allImageError: null,
  // favorite image
  favoriteImage: [],
  favoriteImageStatus: "idle",
  favoriteImageError: null,
};

const GallerySlice = createSlice({
  name: "gallery",
  initialState,
  extraReducers: (builder) => {
    builder
    // all image
      .addCase(getAsyncAllImages.pending, (state) => {
        state.allImageStatus = "loading";
      })
      .addCase(getAsyncAllImages.fulfilled, (state, action) => {
        state.allImageStatus = "fulfilled";
        state.allImage = action.payload;
      })
      .addCase(getAsyncAllImages.rejected, (state, action) => {
        state.allImageStatus = "failed";
        state.allImageError = action.allImageError.message;
      })
    // favorite image
      .addCase(getAsyncFavoriteImages.pending, (state) => {
        state.favoriteImageStatus = "loading";
      })
      .addCase(getAsyncFavoriteImages.fulfilled, (state, action) => {
        state.favoriteImageStatus = "fulfilled";
        state.favoriteImage = action.payload;
      })
      .addCase(getAsyncFavoriteImages.rejected, (state, action) => {
        state.favoriteImageStatus = "failed";
        state.favoriteImageError = action.favoriteImageError.message;
      });
  },
});

export default GallerySlice.reducer;
