import { useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createAsyncWaitlist } from "../../Redux/features/WaitlistSlice";
import Swal from "sweetalert2";
import { createAsyncReservation } from "../../Redux/features/ReservationSlice";

const UserInfo = ({ setGoSteps, selections, dateAndTime, mode }) => {
  console.log(mode);
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const dispatch = useDispatch();
  const phonePrefix = "+961";

  const lebanesePhoneNumberRegex = /^(?:(?:\+|00)961)?(?:0|1)?[0-9]\d{7}$/;

  // Handle input change
  const handleInputChange = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    if (inputName === "phone") {
      setPhone(inputValue);
      const finalContact = phonePrefix + inputValue;
      if (!lebanesePhoneNumberRegex.test(finalContact)) {
        setValidationMessage("Please enter a valid Lebanese phone number.");
      } else {
        setValidationMessage("");
      }
    } else if (inputName === "name") {
      setName(inputValue);
    }
  };

  // Handle next step
  const handleNextStep = () => {
    const finalContact = phonePrefix + phone;
    let data;
    if (mode === "reservation") {
      // Set data for reservation mode
      data = {
        name: name,
        phone: finalContact,
        reservationDate: dateAndTime.reservationDate,
        reservationTime: dateAndTime.reservationTime,
      };
      if (!lebanesePhoneNumberRegex.test(finalContact)) {
        setValidationMessage("Please enter a valid Lebanese phone number.");
      } else {
        setValidationMessage("");
        Swal.fire({
          title: "Are you sure?",
          text: "Are you sure with these data?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(
              createAsyncReservation({
                employeeId: selections.employeeId,
                serviceId: selections.serviceId,
                data,
                navigate,
              })
            );
          }
        });
        console.log(selections.employeeId, selections.serviceId, data);
      }
    } else {
      // Set data for waitlist mode
      data = {
        name: name,
        phone: finalContact,
      };
      if (!lebanesePhoneNumberRegex.test(finalContact)) {
        setValidationMessage("Please enter a valid Lebanese phone number.");
      } else {
        setValidationMessage("");
        Swal.fire({
          title: "Are you sure?",
          text: "Are you sure with these data?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(
              createAsyncWaitlist({
                employeeId: selections.employeeId,
                serviceId: selections.serviceId,
                data,
                navigate,
              })
            );
          }
        });
        console.log(selections.employeeId, selections.serviceId, data);
      }
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-10 col-lg-9 col-xxl-7 col-12 py-2 text-center custom-border">
          <div className="row">
            <div className="col-2 text-start">
              <AiOutlineArrowLeft
                style={{ background: "var(--primary-color)" }}
                onClick={() => setGoSteps(2)}
                className=" gj-cursor-pointer back-btn display-1 p-1 rounded"
              ></AiOutlineArrowLeft>
            </div>
            <div className="col-8 text-center pb-3">
              <h2 className=" display-4 fw-bold ">Information</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 my-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Enter your name
              </label>
              <input
                type="text"
                className="form-control form-control-lg p-3"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="col-10 my-3">
              <label htmlFor="exampleInputEmail1" className="form-label mb-0">
                Enter Your Phone Number
              </label>
            </div>
            <div className="col-10 mb-3 ">
              <div className="input-group">
                <span className="input-group-text px-5" id="basic-addon1">
                  {phonePrefix}
                </span>
                <input
                  type="text"
                  className="form-control form-control-lg p-3"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              {validationMessage && (
                <p className="text-danger">{validationMessage}</p>
              )}
            </div>
            <div className="col-8 col-lg-6 mx-auto">
              <button
                onClick={handleNextStep}
                className="boxed-btn d-block mx-auto my-5 w-100 px-0"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
