import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAsyncEmployees } from "../../Redux/features/AboutSlice";

const Team = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAsyncEmployees());
  }, [dispatch]);

  const employees = useSelector((state) => state.About.employees);
  const status = useSelector((state) => state.About.employeesStatus);

  return (
    <>
      <div className="team-area pb-180 pt-5 mt-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 mb-5 col-lg-8 col-md-11 col-sm-11">
              <div className="section-tittle text-center mb-100">
                <p>Professional Teams</p>
                <h2>Our award winner hair cut exparts for you</h2>
              </div>
            </div>
          </div>
          <div className="row team-active dot-style justify-content-center">
            {status === "loading" ? (
              <>
                <div
                  style={{ height: "100vh" }}
                  className="d-flex mt-5 justify-content-center"
                >
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                {" "}
                {employees?.slice(0, 4).map((singleEmp) => (
                  <div key={singleEmp.id} className="col-lg-3 pb-80 col-md-6 col-sm-12">
                    <div className="single-team  text-center">
                      <div className="team-img">
                        <img src={singleEmp.url} alt="" />
                      </div>
                      <div className="team-caption">
                        <span>{singleEmp.title}</span>
                        <h3 className="text-white">{singleEmp.name}</h3>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
