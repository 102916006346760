import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAsyncSchedule } from "../../Redux/features/CommonSlice";
import { getAsyncContact } from "../../Redux/features/ContactSlice";

const Schedule = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAsyncSchedule());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAsyncContact());
  }, [dispatch]);

  const getSchedule = useSelector((state) => state.Common.schedule);
  const getContact = useSelector((state) => state.Contact.contacts);

  return (
    <div className="container py-5">
      <div className="row justify-content-around py-5">
        <div className="custom-border col-lg-5 col-11 text-center p-5 my-5">
          <h2 className="text-uppercase mb-5 display-3 fw-bold">We're Open</h2>
          <div className="mb-5">
            <span className="d-block header-line"></span>
            <span className="d-block header-line2"></span>
          </div>
          <div className="row">
            <div className="col-6">
              <p className="fw-semibold">Saturday</p>
              <p className="fw-semibold">Sunday</p>
              <p className="fw-semibold">Monday</p>
              <p className="fw-semibold">Tuesday</p>
              <p className="fw-semibold">Wednesday</p>
              <p className="fw-semibold">Thursday</p>
              <p className="fw-semibold">Friday</p>
              <div className="d-deco"></div>
            </div>
            <div className="col-6">
              <p className="fw-bold">Closed Day</p>
              <p className="fw-bold">Closed Day</p>
              <p className="fw-bold">10.00 AM - 6.00 PM</p>
              <p className="fw-bold">10.00 AM - 6.00 PM</p>
              <p className="fw-bold">10.00 AM - 6.00 PM</p>
              <p className="fw-bold">10.00 AM - 6.00 PM</p>
              <p className="fw-bold">10.00 AM - 6.00 PM</p>
            </div>
          </div>
        </div>

        <div className="custom-border col-lg-5 col-11 text-center p-5 my-5">
          <h2 className="text-uppercase mb-5 display-3 fw-bold">Location</h2>
          <div className="mb-5">
            <span className="d-block header-line"></span>
            <span className="d-block header-line2"></span>
          </div>
          <div className="d-col">
            <p className="fw-semibold">Address</p>
            <p className="display-5 fw-bold my-4">{getContact?.address}</p>
          </div>
          <div className="d-col">
            <p className="fw-semibold">Phone</p>
            <p className="display-5 fw-bold my-4">{getContact?.phone}</p>
          </div>
          <div className="d-deco"></div>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
