import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAsyncFavoriteImages } from "../../Redux/features/GallerySlice";

function Gallery() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAsyncFavoriteImages());
  }, [dispatch]);

  const favoriteImages = useSelector((state) => state.Gallery.favoriteImage);

  return (
    <div className="mt-5">
      <div className="container">
        <div className="row no-gutters justify-content-center mb-5 pb-2">
          <div className="col-md-6 text-center heading-section ftco-animate">
            <p className="fw-bold">Gallery</p>
            <h2 className="text-uppercase mb-5 display-1 fw-bold">
              Our gallery
            </h2>
            <div className="mb-5">
              <span className="d-block header-line"></span>
              <span className="d-block header-line2"></span>
            </div>
            <div className="spacer-single"></div>
          </div>
        </div>
      </div>
      <section className="mb-3 px-1">
        <div className="container-fluid">
          <div id="gallery" className="row g-3 justify-content-center">
            {favoriteImages?.slice(0, 4).map((gallery, index) => (
              <div key={index} className="col-md-6 col-lg-3 col-12">
                <div className="de-image-hover rounded ">
                  <span className="dih-overlay"></span>
                  <img src={gallery.url} className="lazy gallery-img" alt="" />
                </div>
              </div>
            ))}
          </div>
          <div className="col-lg-6 col-8 text-center mx-auto mt-5 mb-5">
            <Link to="/gallery">
              <a href="service-head" className="button boxed-btn">
                See all photos
              </a>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Gallery;
