import PageHeader from "../../component/PageHeader/PageHeader";
import Schedule from "../../component/Schedule/Schedule";

import React, { useEffect } from "react";
import ServicesCompTwo from "../../component/ServicesComp/ServicesCompTwo";
import { useDispatch, useSelector } from "react-redux";
import { getAsyncCategories } from "../../Redux/features/CommonSlice";

const Seperator = () => {
  return (
    <div className="my-4 col-11 mx-auto">
      <span className="d-block header-line-pricing"></span>
    </div>
  );
};

const Listing = (props) => {
  const { title, price, last } = props;
  const cls = `col-2 ms-auto text-end  fw-bold mb-0 ${last && "mb-5"}`;

  return (
    <>
      <p className="col-8 mb-0">{title}</p>
      <p className={cls}>{price}</p>
    </>
  );
};

const ServicesPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAsyncCategories());
    window.scrollTo(0, 0);
  }, [dispatch]);
  const categories = useSelector((state) => state.Common.categories);

  return (
    <div id="service-head">
      <PageHeader name={"Services"}></PageHeader>

      <div className="container-fluid my-5">
        <div className="row justify-content-center">
          {categories.filter(category => category.services && category.services.length > 0).map((category, index) => (
            <div className="col-lg-5 mx-auto col-11" key={index}>
              <div className="row justify-content-center">
                <div className="col-12 p-3 my-5 custom-border">
                  <h1 className="mb-4">{category.name}</h1>
                  
                  {category.services.map((service, serviceIndex) => (
                    <div className="row mx-4" key={serviceIndex}>
                      <Listing
                        title={service.name}
                        price={service.waitlistPriceInDollar}
                      />
                      <Seperator />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <section className="service-area py-5 my-5">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-11 col-sm-11">
              <div className="section-tittle text-center mb-90 my-5">
                <span>Professional Services</span>
                <h2>Our Best services that we offering to you</h2>
              </div>
            </div>
          </div>
          {/* <ServicesCompOne /> */}
          <ServicesCompTwo />
        </div>
      </section>
      {/* <Services></Services> */}
      <Schedule />
    </div>
  );
};

export default ServicesPage;
