import React, { useEffect } from "react";
import PageHeader from "../../component/PageHeader/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import { getTodaysWaitlist } from "../../Redux/features/WaitlistSlice";
import Loading from "../../component/Loading";

const WaitlistPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTodaysWaitlist());
  }, [dispatch]);
  const todaysWaitlist = useSelector((state) => state.Waitlist.todaysWaitlist);
  console.log(todaysWaitlist);
  const status = useSelector((state) => state.Waitlist.status);

  return (
    <>
      <PageHeader name={"WaitList Page"}></PageHeader>
      <div className="container my-5 py-5">
        <div className="row justify-content-center">
          <div className=" col-md-10 col-lg-9 col-xxl-6 col-11 py-5 custom-border">
            <div className="row">
              <div className="col-12">
                <p className="text-center mb-0">BarberShop</p>
                <h2 className="text-center display-3 fw-bold mb-0">Waitlist</h2>
                <p className="text-center mb-0 fw-semibold">
                  3 waiting Estimated wait 1 min
                </p>
              </div>
            </div>
            <div className="px-4">
              {status === "loading" ? (
                <Loading />
              ) : (
                <>
                  <table className="table table-striped mt-5">
                    <thead>
                      <tr>
                        <th scope="col" className="text-secondary">
                          First
                        </th>
                        <th scope="col" className="text-end text-secondary">
                          Estimate Wait
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {todaysWaitlist?.map((waitlist) => (
                        <tr>
                          <td className="fs-3 fw-bold">{waitlist?.name}</td>
                          <td className="text-end fs-3">
                            {waitlist?.waitingTime} min
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>
            {/* <hr
              className="my-4"
              style={{ backgroundColor: "var(--primary-color)" }}
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default WaitlistPage;
