import { useEffect, useState } from "react";
import { FaLocationArrow } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import { ContactSchema } from "../../component/ValidationSchema";
import { getAsyncContact, postAsyncContact } from "../../Redux/features/ContactSlice";

const Contact = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [verified, setVerified] = useState(false);
  const phonePrefix = "+961";

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name,
      phone: phonePrefix + phone,
      subject,
      message,
    };
    try {
      await ContactSchema.validate(data);
      dispatch(postAsyncContact(data));
      Swal.fire("Send!", "Your Message has been send.", "success");
      setName("");
      setPhone("");
      setSubject("");
      setMessage("");
    } catch (error) {
      Swal.fire("Validation Error", error.message, "error");
    }
  };

  // Validate field individually
  const validateField = async (name, value) => {
    try {
      // Validate the field only
      await ContactSchema.validateAt(name, { [name]: value });
      setErrors((prev) => ({ ...prev, [name]: "" }));
    } catch (error) {
      // Set error message if validation fails
      setErrors((prev) => ({ ...prev, [name]: error.message }));
    }
  };

  // Handle field change
  const handleFieldChange = (name, value) => {
    // Set the touched state
    setTouched((prev) => ({ ...prev, [name]: true }));
    // Validate the field
    validateField(name, value);
  };

  // Fetch data from API
  useEffect(() => {
    dispatch(getAsyncContact());
  }, [dispatch]);
  const contact = useSelector((state) => state.Contact.contacts);

  // Captcha
  function onChange(value) {
    console.log("Captcha value:", value);
    setVerified(true);
  }

  return (
    <>
      <div className="slider-area2">
         <div className="slider-height2 d-flex align-items-center">
           <div className="container">
             <div className="row">
               <div className="col-xl-12">
                 <div className="hero-cap hero-cap2 pt-70 text-center">
                   <h2>Contact Us</h2>
                 </div>
               </div>
             </div>
           </div>
        </div>
      </div>
      <section className="contact-section mt-5">
        <div className="container">
          <div>
            <div className="d-none d-sm-block mb-5 pb-4">
              <div
                id="map"
                className="overflow-hidden"
                style={{
                  height: "480px",
                  marginBottom: "70px",
                  position: "relative",
                }}
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26496.124756631863!2d35.483682734698576!3d33.88925221107369!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f17215880a78f%3A0x729182bae99836b4!2sBeirut%2C%20Lebanon!5e0!3m2!1sen!2sbd!4v1698159551657!5m2!1sen!2sbd"
                  style={{ width: "100%", height: "500px", border: "none" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Google Maps"
                ></iframe>
              </div>
            </div>
            <div className="mb-5 pb-4 text-center">
              <div className="row justify-content-center ">
                <div className="media col-6">
                  <div className="">
                    <FaLocationArrow className="display-5 mb-3" />
                    <div className="media-body">
                      <p className="mb-0 fw-bold">{contact.address}</p>
                    </div>
                  </div>
                </div>
                <div className="media col-6">
                  <div className="">
                    <FaPhone className="display-5 mb-3" />
                    <div className="media-body">
                      <p className="mb-0 fw-bold">{contact.phone}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5 mt-lg-0">
              <div className="row mb-5">
                <div className="col-12">
                  <h2 className="contact-title">Get in Touch</h2>
                </div>
              </div>
              <div className="col-lg-12">
                <form
                  onSubmit={handleFormSubmit}
                  className="form-contact contact_form"
                  action="contact_process.php"
                  method="post"
                  id="contactForm"
                >
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                          className="form-control valid"
                          name="name"
                          value={name}
                          id="name"
                          type="text"
                          placeholder="Enter your name"
                          onChange={(e) => {
                            setName(e.target.value);
                            handleFieldChange("name", e.target.value);
                          }}
                          onBlur={() => handleFieldChange("name", name)}
                        />
                        {touched.name && errors.name && (
                          <p className="lead fs-6" style={{ color: "red" }}>
                            {errors.name}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="Phone">Phone</label>
                        <div className="input-group">
                        <span className="input-group-text transperant-bg fs-5">{phonePrefix}</span>
                        <input
                          className="form-control valid"
                          name="phone"
                          id="phone"
                          value={phone}
                          type="phone"
                          placeholder="Phone"
                          onChange={(e) => {
                            setPhone(e.target.value);
                            handleFieldChange("phone", e.target.value);
                          }}
                          onBlur={() => handleFieldChange("phone", phone)}
                        />
                        </div>
                        {touched.phone && errors.phone && (
                          <p className="lead fs-6" style={{ color: "red" }}>
                            {errors.phone}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="Subject">Subject</label>
                        <input
                          className="form-control"
                          name="subject"
                          id="subject"
                          value={subject}
                          type="text"
                          placeholder="Enter Subject"
                          onChange={(e) => {
                            setSubject(e.target.value);
                            handleFieldChange("subject", e.target.value);
                          }}
                          onBlur={() => handleFieldChange("subject", subject)}
                        />
                        {touched.subject && errors.subject && (
                          <p className="lead fs-6" style={{ color: "red" }}>
                            {errors.subject}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea
                          className="form-control w-100"
                          name="message"
                          id="message"
                          value={message}
                          cols="30"
                          rows="9"
                          placeholder=" Enter Message"
                          onChange={(e) => {
                            setMessage(e.target.value);
                            handleFieldChange("message", e.target.value);
                          }}
                          onBlur={() => handleFieldChange("message", message)}
                        ></textarea>
                        {touched.message && errors.message && (
                          <p className="lead fs-6" style={{ color: "red" }}>
                            {errors.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group mt-3 text-center text-lg-start">
                    <ReCAPTCHA
                      sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                      onChange={onChange}
                      className="mb-3"
                    />
                    <button
                      type="submit"
                      className="btn btn--tra-black hover--black"
                      disabled={!verified}
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
