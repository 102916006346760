import { useEffect, useState } from "react";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getAsyncSocialLinks } from "../../Redux/features/CommonSlice";
import { FaTiktok } from "react-icons/fa";

const Navbar = () => {
  const location = useLocation();
  const currentRoute = location.pathname;
  const [active, setActive] = useState("/");
  const dispatch = useDispatch();

  useEffect(() => {
    setActive(currentRoute);
  }, [currentRoute]);

  const handleActive = () => {
    setActive(currentRoute);
  };
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    dispatch(getAsyncSocialLinks());
  }, [dispatch]);

  const socialLink = useSelector((state) => state.Common.socialLinks);

  return (
    <div>
      <div className="header-area header-transparent  fixed-top">
        <div
          style={
            isScrolled
              ? { backgroundColor: "var(--secondary-color)", transition: ".2s" }
              : {}
          }
          className="main-header navbar-wrapper header-sticky"
        >
          <div className="container-fluid d-none pt-20 d-lg-block">
            <div className="row align-items-center">
              <div className="col-xl-2 col-lg-2 col-md-1">
                <div className="logo">
                  <Link to="/">
                    <img className="w-100" src="img/logo/ABSS.webp" alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-xl-10 col-lg-10 col-md-10">
                <div className="menu-main d-flex align-items-center justify-content-end">
                  <div className="main-menu f-right d-none d-lg-block">
                    <nav>
                      <ul id="navigation">
                        <li
                          onClick={() => handleActive()}
                          className={`${active === "/" ? "active" : ""}`}
                        >
                          <Link className="">Home</Link>
                        </li>
                        <li
                          onClick={() => handleActive()}
                          className={`${active === "/about" ? "active" : ""}`}
                        >
                          <Link to="about">About</Link>
                        </li>
                        <li
                          onClick={() => handleActive()}
                          className={`${
                            active === "/services" ? "active" : ""
                          }`}
                        >
                          <Link to="services">Services</Link>
                        </li>
                        <li
                          onClick={() => handleActive()}
                          className={`${active === "/gallery" ? "active" : ""}`}
                        >
                          <Link to="gallery">Gallery</Link>
                        </li>
                        <li
                          onClick={() => handleActive()}
                          className={`${active === "/contact" ? "active" : ""}`}
                        >
                          <Link to="contact">Contact</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div className="header-right-btn f-right d-none d-lg-block ml-30">
                    <div className="footer-social f-right mx-5">
                      <a
                        href={socialLink?.facebook}
                        target="blank"
                        className="fs-1"
                        style={{ color: "var(--primary-color)" }}
                      >
                        <FaFacebook />
                      </a>
                      <a
                        href={socialLink?.instagram}
                        target="blank"
                        style={{ color: "var(--primary-color)" }}
                        className="mx-4 fs-1"
                      >
                        <FaInstagram />
                      </a>
                      <a
                        href={socialLink?.tiktok}
                        target="blank"
                        className="fs-1"
                        style={{ color: "var(--primary-color)" }}
                      >
                        <FaTiktok />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <nav
            className="navbar bg-transparent d-lg-none d-block px-2"
            data-bs-theme="dark"
          >
            <div className="container-fluid">
              <div className="logo">
                <Link to="/">
                  <img width={100} src="img/logo/ABSS.webp" alt="" />
                </Link>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="offcanvas offcanvas-end bg-gold"
                tabIndex="-1"
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel"
              >
                <div className="offcanvas-header">
                  <h5
                    className="offcanvas-title"
                    id="offcanvasNavbarLabel"
                  ></h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="">
                  <ul className="mobile-nav">
                    <li
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                      className="py-3"
                    >
                      <Link
                        onClick={() => handleActive("home")}
                        className={` mobile-nav-text ${
                          active === "home" ? "mobile-nav-active" : ""
                        }`}
                      >
                        Home
                      </Link>
                    </li>
                    <li
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                      className="py-3"
                    >
                      <Link
                        onClick={() => handleActive("about")}
                        className={` mobile-nav-text ${
                          active === "about" ? "mobile-nav-active" : ""
                        }`}
                        to="about"
                      >
                        About
                      </Link>
                    </li>
                    <li
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                      className="py-3"
                    >
                      <Link
                        onClick={() => handleActive("service")}
                        className={` mobile-nav-text ${
                          active === "service" ? "mobile-nav-active" : ""
                        }`}
                        to="services"
                      >
                        Services
                      </Link>
                    </li>
                    <li
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                      className="py-3"
                    >
                      <Link
                        onClick={() => handleActive("gallery")}
                        className={` mobile-nav-text ${
                          active === "gallery" ? "mobile-nav-active" : ""
                        }`}
                        to="gallery"
                      >
                        Gallery
                      </Link>
                    </li>
                    <li
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                      className="py-3"
                    >
                      <Link
                        onClick={() => handleActive("contact")}
                        className={` mobile-nav-text ${
                          active === "contact" ? "mobile-nav-active" : ""
                        }`}
                        to="contact"
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
