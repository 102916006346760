import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAsyncCategories } from "../../Redux/features/CommonSlice";

const Services = () => {
  const [active, setActive] = useState("home");
  const handleActive = (id) => {
    setActive(id);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAsyncCategories());
  }, [dispatch]);
  const categories = useSelector((state) => state.Common.categories);

  return (
    <>
      <section className="service-area py-5 my-5">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-11 col-sm-11">
              <div className="section-tittle text-center mb-90 my-5">
                <p>Professional Services</p>
                <h2>Our Best services that we offering to you</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {categories?.slice(0, 3).map((category) => (
              <div key={category.id} className="col-lg-3 col-md-5 mx-2 my-4">
                <div className="services-caption text-center">
                  <div className="service-icon">
                    <img
                      className="img-fluid rounded-4"
                      width={120}
                      src={category?.url}
                      alt=""
                    />
                  </div>
                  <div className="service-cap">
                    <h4>
                      <Link to={"/services"}>{category?.name}</Link>
                    </h4>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6 col-8 text-center mt-5">
              <br />
              <li
                onClick={() => handleActive("services")}
                className={`${active === "services" ? "active" : ""}`}
              >
                <Link to="/services">
                  <a href="service-head" className="button boxed-btn">
                    {" "}
                    see all services and pricing
                  </a>
                </Link>
              </li>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
