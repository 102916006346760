import { useState } from "react";
import OtpInput from "react-otp-input";
import { BiRightArrowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { VerifyAsyncPhoneNumber } from "../../Redux/features/WaitlistSlice";

const Otp = ({ setGoSteps }) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  console.log(otp);

  const waitlistId = useSelector((state) => state.Waitlist.waitlist.id);
  console.log(waitlistId);

  // Submit OTP
  const handleSubmit = () => {
    setGoSteps(6);
    // console.log({ waitlistId: waitlistId, data: { verificationCode: otp } });
    // dispatch(VerifyAsyncPhoneNumber({ waitlistId: waitlistId, data: { verificationCode: otp } }));
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div
          id="dialog"
          className="col-md-10 col-lg-9 col-xxl-7 col-12 py-2 text-center custom-border mobile-height"
        >
          <div className="row">
            <div className="col-2 text-start">
              <AiOutlineArrowLeft
                style={{ background: "var(--primary-color)" }}
                onClick={() => setGoSteps(4)}
                className=" gj-cursor-pointer back-btn display-1 p-1 rounded"
              ></AiOutlineArrowLeft>
            </div>
            <div className="col-8 text-center">
              <h2 className=" display-4 fw-bold ">
                Please enter the verification code
              </h2>
            </div>
          </div>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            inputType={true}
            containerStyle="custom-otp-container"
            inputStyle="custom-otp-input"
            renderSeparator={
              <span className="fw-bold display-6">
                <BiRightArrowAlt className="d-none d-lg-block"></BiRightArrowAlt>
              </span>
            }
            renderInput={(props) => (
              <input style={{ width: "4rem" }} {...props} />
            )}
          />
          <Link to="/confirmation#confirm">
            <button
              onClick={handleSubmit}
              className="boxed-btn d-block mx-auto my-5 w-50 px-0"
            >
              Verify
            </button>
          </Link>
          <div className="text-center mt-5">
            <Link className="gj-cursor-pointer text-dark">Send code again</Link>
            <br />
            <Link className="gj-cursor-pointer text-dark">
              Change phone number
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otp;
